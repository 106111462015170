import React from "react"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout title="We build Apps">
    <p>Siteware is all about application development.</p>
    <p>We love to create the application that suits your needs and help you be succesful in your business journey.
       At Siteware we believe that great things are created through collaboration.</p>
    <p>Do you need a partner to make your next project come to live? Contact us and see what we can do for you.</p>

  </Layout>
)

export default IndexPage
